import React, { useEffect, useState } from "react";
import { getLeadsV2, mapTypeToColor } from "./utilities";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import { getManagers, getSalesTeamForLocation } from "../Admin/Utilities/api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { selectUser } from "../../features/userSlice";
import { normalizeDate } from "../../utilities";
import RequestSnackbar from "../../components/Other/RequestSnackbar";
import DescriptionIcon from "@mui/icons-material/Description";
import LoadingSpinner from "../../components/Other/LoadingSpinner";
import { selectRoles } from "../../features/roleSlice";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const WebleadManager = (props) => {
  const [leads, setLeads] = useState(null);
  const [managers, setManagers] = useState(null);
  const [salesTeam, setSalesTeam] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(10);
  const [noteData, setNoteData] = useState({});
  const [selectedManager, setManager] = useState(null);
  const [selectedSales, setSales] = useState(null);
  const [confirmation, openConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [logs, setLogs] = useState([]);
  const [cardData, setCardData] = useState({
    telephonerepid: 0,
  });

  const [flag, setFlag] = useState(false);

  const user = useSelector(selectUser);
  const roles = useSelector(selectRoles);

  // const submitNewCustomer = async () => {
  //   data = cardData;

  //   data.key = "0kb4947sfwyj1zzcm34cbn9e7flmd8f3";

  //   var data = JSON.stringify(cardData);

  //   const rawResponse = await fetch("/api/changelog/submitUserTest", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //     body: data,
  //   });
  //   const content = await rawResponse.json();
  // };

  useEffect(() => {
    // Parse the current URL to get the search parameters
    const queryParams = new URLSearchParams(window.location.search);

    // Get the value of the 'sq' parameter
    const sq = queryParams.get("sq");

    // Update the state with the value of 'sq'
    if (sq) {
      setSearchQuery(sq);
    }
  }, []);

  useEffect(() => {
    const fetchData = () => {
      if (props.branch === "All Locations") {
        getLeadsV2(setLeads, props.type, null, "ALL");
      } else {
        getLeadsV2(
          setLeads,
          props.type,
          props.selectedUser === "ALL" ? props.branch : null,
          props.selectedUser === "ALL" ? "ALL" : props.selectedUser
        );
      }
      switch (user.name) {
        case "Ben Chambers":
          getSalesTeamForLocation(setSalesTeam, "Eastern");
          break;
        case "Scott Foley":
          getSalesTeamForLocation(setSalesTeam, "Western");
          break;
        default:
          getSalesTeamForLocation(setSalesTeam, props.branch);
          break;
      }
    };

    // Fetch data initially
    fetchData();
  }, [props.type, props.branch, props.user, props.selectedUser, flag]);

  useEffect(() => {
    setCurrentPage(1);
  }, [props.type, props.branch, props.user, props.selectedUser, flag]);

  useEffect(() => {
    if (!managers) {
      getManagers(setManagers);
    }
  }, []);

  useEffect(() => {
    if (selectedManager) {
      assignManager(selectedManager);
    }
  }, [selectedManager]);

  useEffect(() => {
    if (selectedSales) {
      assignSales(selectedSales);
    }
  }, [selectedSales]);

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };

  const filteredLeads = leads
    ? leads.filter((lead) =>
        Object.values(lead.webleadEntry).some((value) =>
          value.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : [];

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredLeads.slice(indexOfFirstCard, indexOfLastCard);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const insertNewNote = async () => {
    const rawResponse = await fetch("/api/leads/v2/addNote", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(noteData),
    });
  };

  const updateStatus = async (id, type, status, reason) => {
    const data = {
      user: user.name,
      id: id,
      type: type,
      status: status,
      reason: reason,
    };

    const rawResponse = await fetch("/api/leads/v2/updateProgress", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if ((rawResponse.message = "Web Lead Updated")) {
      await setFlag(!flag);
    }
  };

  const assignManager = async (data) => {
    data.assignedBy = user;

    const rawResponse = await fetch("/api/leads/v2/assignManager", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const responseData = await rawResponse.json();

    if (responseData.message === "Web Lead Assigned") {
      await setConfirmationMessage(
        `Manager ${data.Name} assigned to Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }
  };

  const assignSales = async (data) => {
    data.assignedBy = user;

    const rawResponse = await fetch("/api/leads/v2/assignSales", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    // Parsing the JSON response from the server
    const responseData = await rawResponse.json();

    if (responseData.message === "Web Lead Assigned") {
      await setConfirmationMessage(
        `Sales ${data.Name} assigned to Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }

    if (responseData.message === "User already assigned") {
      await setConfirmationMessage(
        `${data.Name} is already assigned to Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }
  };

  const removeSales = async (data) => {
    data.assignedBy = user;

    const rawResponse = await fetch("/api/leads/v2/removeSales", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    // Parsing the JSON response from the server
    const responseData = await rawResponse.json();

    if (responseData.message === "Web Lead Assigned Removed") {
      await setConfirmationMessage(
        `Sales ${data.Name} removed from Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }

    if (responseData.message === "User Not Assigned!") {
      await setConfirmationMessage(
        `${data.Name} is not assigned to Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }
  };

  const removeManager = async (data) => {
    data.assignedBy = user;

    const rawResponse = await fetch("/api/leads/v2/removeManager", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    // Parsing the JSON response from the server
    const responseData = await rawResponse.json();

    if (responseData.message === "Web Lead Assigned Removed") {
      await setConfirmationMessage(
        `Manager ${data.Name} removed from Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }

    if (responseData.message === "User Not Assigned!") {
      await setConfirmationMessage(
        `${data.Name} is not assigned to Weblead ID: ${data.id}`
      );
      await openConfirmation(true);
      await setFlag(!flag);
    }
  };

  // const handleOpenAddDialog = () => {
  //   setOpenAddDialog(true);
  // };

  // const handleCloseAddDialog = () => {
  //   submitNewCustomer();
  //   setCardData({ telephonerepid: 0 });
  //   setOpenAddDialog(false);
  // };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="flex flex-col justify-center items-center">
      {leads ? (
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="m-2 p-2 border rounded text-black"
          />
          {currentCards?.map((item) => {
            return (
              <>
                <div
                  key={item._id}
                  className="flex flex-col w-full mt-2 border border-black/20 shadow justify-between items-center bg-slate-100/50 dark:bg-slate-100/5 dark:border-white"
                >
                  <div className="flex w-full">
                    <div className="flex w-full m-1 ">
                      <div className="flex justify-start">
                        <Tooltip title="Contact Form Type">
                          <div
                            className={`p-1 border rounded ${mapTypeToColor(
                              item.webleadEntry.type
                            )}`}
                          >
                            <p>{item.webleadEntry.type}</p>
                          </div>
                        </Tooltip>

                        {item.webleadEntry.hearAbout && (
                          <Tooltip
                            title={
                              item.webleadEntry.hearAbout === "Other"
                                ? item.webleadEntry.hearOther
                                : "Referral Type"
                            }
                          >
                            <div
                              className={`p-1 ml-1 border rounded ${mapTypeToColor(
                                item.webleadEntry.hearAbout
                              )}`}
                            >
                              <p>{item.webleadEntry.hearAbout}</p>
                            </div>
                          </Tooltip>
                        )}
                        {item.webleadEntry.customerWorkingOn && (
                          <Tooltip title="Customer Working On">
                            <div
                              className={`p-1 ml-1 border rounded ${mapTypeToColor(
                                item.webleadEntry.customerWorkingOn
                              )}`}
                            >
                              <p>{item.webleadEntry.customerWorkingOn}</p>
                            </div>
                          </Tooltip>
                        )}
                        <div className={`p-1 ml-1`}>
                          <Tooltip title="Date Submitted">
                            <p>{normalizeDate(item.webleadEntry.entryDate)}</p>
                          </Tooltip>
                        </div>
                        {item.assignInfo?.salesAssigned ? (
                          <div className={`p-1 ml-1`}>
                            <Tooltip title="Assign a Salesperson to this lead!">
                              {item.assignInfo.assignedSales.length < 1 && (
                                <p className="text-red-700 font-black animate-pulse">
                                  *No Sales Assigned*
                                </p>
                              )}
                            </Tooltip>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      {/* <div className="flex justify-center m-1 p-1 rounded border border-black text-blue-500 hover:text-blue-500/50 ">
                        <button
                          onClick={() => {
                            setCardData({
                              name: item.webleadEntry.customerName,
                              contactname: item.webleadEntry.customerName,
                              contactnumber: item.webleadEntry.customerPhone,
                              contactemailaddress:
                                item.webleadEntry.customerEmail,

                              deliveryaddress1:
                                item.webleadEntry.customerAddress,
                              deliverycity: item.webleadEntry.customerCity,
                              deliverycounty: item.webleadEntry.customerState,
                              deliverypostcode: item.webleadEntry.customerZip,
                            });
                            console.log(cardData);
                            handleOpenAddDialog();
                          }}
                        >
                          Import to BisTrack
                        </button>
                      </div> */}
                    </div>

                    <div className="flex justify-end">
                      {item.logs.length > 0 ? (
                        <Tooltip title="Logs">
                          <button
                            onClick={() => {
                              handleOpenDialog();
                              setLogs(item.logs);
                            }}
                          >
                            <DescriptionIcon />
                          </button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="No Logs">
                          <button disabled className="cursor-not-allowed">
                            <DescriptionIcon />
                          </button>
                        </Tooltip>
                      )}

                      {props.type !== "Completed" &&
                        props.type !== "Deleted" && (
                          <div className="flex">
                            <button
                              onClick={() => {
                                updateStatus(item._id, "completed", 1, null);
                              }}
                              className="m-1 w-24 p-1 rounded border border-black text-green-500 hover:text-green-500/50"
                            >
                              Complete
                            </button>
                            <button
                              onClick={() => {
                                updateStatus(
                                  item._id,
                                  "deleted",
                                  1,
                                  `System Delete From ${user.name}`
                                );
                              }}
                              className="m-1 w-24 p-1 rounded border border-black text-red-500 hover:text-red-500/50"
                            >
                              Delete
                            </button>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="flex w-full">
                    <div className="flex flex-col w-1/4 m-1">
                      <div className="flex w-full border-b">
                        <p className="font-bold">Contact Info</p>
                      </div>
                      <div className="flex">
                        <PersonIcon className="mr-1" />
                        <p>{item.webleadEntry.customerName}</p>
                      </div>
                      <Tooltip title="Click to send Email!">
                        <a
                          className="hover:text-blue-400"
                          onClick={() => {
                            if (user.name !== "Alec Evans") {
                              updateStatus(item._id, "email", true, null);
                            }
                          }}
                          target="_blank"
                          href={`mailto:${item.webleadEntry.customerEmail}?subject=${item.webleadEntry.customerWorkingOn} - ${item.webleadEntry.customerName}&bcc=webleadtracking@bigclumber.com`}
                        >
                          <div className="flex flex-wrap">
                            <EmailIcon className="mr-1" />

                            {item.webleadEntry.customerEmail}
                          </div>
                        </a>
                      </Tooltip>

                      <div className="flex">
                        <PhoneIcon className="mr-1" />
                        <p>
                          {item.webleadEntry.customerPhone
                            ? item.webleadEntry.customerPhone
                            : "None Provided"}
                        </p>
                      </div>
                      <Tooltip title="Click to view on Map!">
                        <a
                          className="hover:text-blue-400"
                          target="_blank"
                          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                            item.webleadEntry.customerAddress
                          )}`}
                        >
                          <div className="flex">
                            <HomeIcon className="mr-1" />

                            {item.webleadEntry.customerAddress}
                          </div>
                        </a>
                      </Tooltip>
                    </div>
                    <div className="flex flex-col w-2/4 m-1">
                      <div className="flex flex-col max-h-64 overflow-auto">
                        <div className="flex w-full border-b">
                          <p className="font-bold">Looking For</p>
                        </div>
                        <p className="text-xl">
                          {item.webleadEntry.customerLookingFor}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col w-1/4 m-1">
                      <div className="flex flex-col">
                        <div className="flex w-full border-b">
                          <p className="font-bold">Assignment</p>
                        </div>
                        <div className="flex mb-1 border-b p-1 items-center">
                          <p className="w-1/4 mr-1">Manager</p>
                          {!item.assignInfo?.managerAssigned &&
                          (roles.roles.includes("Web Lead Manager") ||
                            roles.roles.includes("General Manager")) ? (
                            <select
                              onChange={(e) => {
                                const selectedIndex =
                                  e.target.selectedIndex - 1;
                                const selectedManager = managers[selectedIndex];
                                const managerWithId = {
                                  ...selectedManager,
                                  id: item._id, // Assuming _id is the property name
                                };
                                setManager(managerWithId);
                              }}
                              className="w-3/4 bg-white"
                            >
                              <option value="">Select a manager</option>
                              {managers &&
                                managers.map((item, index) => (
                                  <option key={index} value={item.UserID}>
                                    {item.Name} - {item.Branch}
                                  </option>
                                ))}
                            </select>
                          ) : (
                            <div className="m-1 p-1 border-yellow-900 border rounded">
                              <p className="text-yellow-600">
                                {item.assignInfo.assignedManager.Name}
                                {roles.roles.includes("Web Lead Manager") && (
                                  <button
                                    onClick={() => {
                                      removeManager({
                                        id: item._id,
                                        UserID:
                                          item.assignInfo.assignedManager
                                            .UserID,
                                        Name: item.assignInfo.assignedManager
                                          .Name,
                                      });
                                    }}
                                  >
                                    <p className="text-red-500 ml-1">X</p>
                                  </button>
                                )}
                              </p>
                            </div>
                          )}
                        </div>

                        <div className="flex flex-col mb-1 p-1 items-center">
                          <div className="flex">
                            <p className="w-1/4 mr-1">Sales</p>
                            {(roles.roles.includes("Web Lead Manager") ||
                              roles.roles.includes("General Manager")) && (
                              <select
                                onChange={(e) => {
                                  const selectedIndex =
                                    e.target.selectedIndex - 1;
                                  const selectedSales =
                                    salesTeam[selectedIndex];
                                  const salesWithID = {
                                    ...selectedSales,
                                    id: item._id, // Assuming _id is the property name
                                  };
                                  setSales(salesWithID);
                                }}
                                className="w-3/4 bg-white"
                              >
                                <option value="">Assign to Sales</option>
                                {salesTeam &&
                                  salesTeam.map((item, index) => (
                                    <option key={index} value={item.UserID}>
                                      {item.Name} - {item.Branch}
                                    </option>
                                  ))}
                              </select>
                            )}
                          </div>

                          {item.assignInfo?.salesAssigned ? (
                            <div className="flex flex-wrap">
                              {item.assignInfo.assignedSales.map(
                                (user, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="flex items-center justify-center m-1 p-1 border-yellow-900 border rounded"
                                    >
                                      <p className="text-yellow-600 text-xs">
                                        {user?.Name}
                                      </p>
                                      {(roles.roles.includes(
                                        "Web Lead Manager"
                                      ) ||
                                        roles.roles.includes(
                                          "General Manager"
                                        )) && (
                                        <button
                                          onClick={() => {
                                            removeSales({
                                              id: item._id,
                                              UserID: user?.UserID,
                                              Name: user?.Name,
                                            });
                                          }}
                                        >
                                          <p className="text-red-500 ml-1">X</p>
                                        </button>
                                      )}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full p-1">
                    <Accordion className="w-1/2 m-1">
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon onClick={() => setNoteData({})} />
                        }
                      >
                        Notes
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="flex flex-col">
                          <div className="flex flex-col w-full max-h-64 overflow-auto mr-1">
                            {item.notes && item.notes.length > 0
                              ? item.notes.map((data) => {
                                  return (
                                    <div className="flex w-full mb-2 border border-black">
                                      <div className="flex flex-col items-center justify-center border-r border-black w-1/4 bg-slate-200">
                                        <p className="font-black text-sm">
                                          {normalizeDate(data.note_date)}
                                        </p>
                                        <p className="font-black text-sm">
                                          {data.username}
                                        </p>
                                      </div>
                                      <div className="w-3/4 bg-slate-100 p-1">
                                        <p>{data.note}</p>
                                      </div>
                                    </div>
                                  );
                                })
                              : "No Notes Found"}
                          </div>
                          <div className="flex items-center justify-center w-full mt-2">
                            <div className="flex w-3/4">
                              <input
                                className="w-full rounded"
                                placeholder="Add Note..."
                                type="text"
                                value={noteData.message}
                                onChange={(e) =>
                                  setNoteData({
                                    id: item._id,
                                    user: user.name,
                                    timestamp: new Date(),
                                    message: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <button
                              onClick={() => {
                                if (noteData.message.trim() !== "") {
                                  insertNewNote();
                                  setNoteData({ ...noteData, message: "" });
                                  setFlag(!flag);
                                }
                              }}
                              className="w-1/4 flex justify-center ml-1 p-2 border rounded border-blue-600 bg-blue-400 hover:bg-blue-300 text-white"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                    <div className="w-1/2 flex flex-col items-center">
                      <div className="border-b w-full flex justify-center">
                        Lead Progress
                      </div>
                      <div className="flex w-full">
                        <div className="flex justify-center w-1/4">
                          <button
                            onClick={() => {
                              updateStatus(
                                item._id,
                                "phone",
                                !item.leadProgress.contactStatus.phoneContact
                                  .status,
                                null
                              );
                            }}
                            className="m-1"
                          >
                            <PhoneIcon
                              className={
                                !item.leadProgress.contactStatus.phoneContact
                                  .status
                                  ? "text-red-800"
                                  : "text-green-800"
                              }
                            />
                          </button>
                          <button
                            onClick={() => {
                              updateStatus(
                                item._id,
                                "email",
                                !item.leadProgress.contactStatus.emailContact
                                  .status,
                                null
                              );
                            }}
                            className="m-1"
                          >
                            <EmailIcon
                              className={
                                !item.leadProgress.contactStatus.emailContact
                                  .status
                                  ? "text-red-800"
                                  : "text-green-800"
                              }
                            />
                          </button>
                        </div>
                        <div className="flex justify-center w-3/4">
                          <div className="justify-center">
                            <label>Quote #</label>
                            <input
                              type="text"
                              value={item.saleData.quoteNumber}
                              className="m-1 w-1/2"
                              onChange={(e) => {
                                updateStatus(
                                  item._id,
                                  "quote",
                                  e.target.value,
                                  null
                                );
                              }}
                            />
                          </div>

                          <div className="justify-center">
                            <label>Order #</label>
                            <input
                              type="text"
                              value={item.saleData.orderNumber}
                              className="m-1 w-1/2"
                              onChange={(e) => {
                                updateStatus(
                                  item._id,
                                  "order",
                                  e.target.value,
                                  null
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="flex mt-4 w-96 overflow-auto">
            {[
              ...Array(Math.ceil(filteredLeads.length / cardsPerPage)).keys(),
            ].map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber + 1)}
                className={`mx-1 px-3 py-1 rounded ${
                  currentPage === pageNumber + 1
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300 text-gray-700"
                }`}
              >
                {pageNumber + 1}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex w-full justify-center items-center">
          <LoadingSpinner />
        </div>
      )}
      {confirmation && (
        <RequestSnackbar
          open={confirmation}
          setOpen={openConfirmation}
          message={confirmationMessage}
        />
      )}
      <Dialog
        className="fixed inset-0 z-10 overflow-y-auto flex w-full justify-center items-center"
        maxWidth="md"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <div className="flex w-full items-center justify-center ">
          <div className="bg-white p-8 rounded">
            <div className="text-lg font-semibold mb-4 flex justify-center">
              Logs
            </div>
            <div className="space-y-2">
              {logs &&
                logs.map((item, index) => (
                  <div className="flex w-full" key={index}>
                    <div className="flex w-1/5 justify-center items-center p-1">
                      <p className="text-sm font-bold">
                        {normalizeDate(item.timestamp)}
                      </p>
                    </div>
                    <div className="w-4/5  justify-center items-center p-1">
                      <p className="text-sm">{item.message}</p>{" "}
                    </div>
                  </div>
                ))}
            </div>
            <div className="flex justify-center">
              <button
                className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={handleCloseDialog}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <Dialog
        className="fixed inset-0 z-10 overflow-y-auto flex w-full justify-center items-center"
        maxWidth="md"
        open={openAddDialog}
        onClose={handleCloseAddDialog}
      >
        <div className="flex w-full items-center justify-center ">
          <div className="bg-white p-8 rounded flex flex-col">
            <div className="flex text-lg font-semibold mb-4 justify-center">
              Add Customer to BisTrack
            </div>
            {cardData && (
              <div className="flex flex-col space-y-2 items-center justify-center">
                <select
                  onChange={(e) => {
                    setCardData({
                      ...cardData,
                      telephonerepid: e.target.value,
                    });
                  }}
                  className="flex w-3/4 bg-white"
                >
                  <option value="">Select Sales Rep</option>
                  {salesTeam &&
                    salesTeam.map((item, index) => (
                      <option key={index} value={item.UserID}>
                        {item.Name} - {item.Branch}
                      </option>
                    ))}
                </select>
                <div className="flex items-center">
                  <p>Name:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        name: e.target.value,
                        contactname: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.name}
                    className="m-1 w-1/2"
                  />
                  <p>Email:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        contactemailaddress: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.contactemailaddress}
                    className="m-1 w-1/2"
                  />
                </div>
                <div className="flex items-center">
                  <p>Phone:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        contactnumber: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.contactnumber}
                    className="m-1 w-1/2"
                  />
                  <p>City:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        deliverycity: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.deliverycity}
                    className="m-1 w-1/2"
                  />
                </div>
                <div className="flex items-center">
                  <p>State:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        deliverycounty: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.deliverycounty}
                    className="m-1 w-1/2"
                  />
                  <p>Zip Code:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        deliverypostcode: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.deliverypostcode}
                    className="m-1 w-1/2"
                  />
                </div>
                <div className="flex items-center">
                  <p>Address:</p>
                  <input
                    onChange={(e) => {
                      setCardData({
                        ...cardData,
                        deliveryaddress1: e.target.value,
                      });
                    }}
                    type="text"
                    value={cardData.deliveryaddress1}
                    className="m-1 "
                  />
                </div>
              </div>
            )}
            <div className="flex justify-center">
              {cardData.telephonerepid ? (
                <button
                  className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleCloseAddDialog}
                >
                  Import
                </button>
              ) : (
                <p>Select Sales Rep for Import</p>
              )}
            </div>
          </div>
        </div>
      </Dialog> */}
    </div>
  );
};

export default WebleadManager;
