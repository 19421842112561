import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import POInfoTable from "../../components/Other/POInfoTable";
import StockedItems from "../../components/Other/StockedItems";

const MillScanner = () => {
  const [woNumber, setWoNumber] = useState("");
  const [woStatus, setWoStatus] = useState(null);
  const [poNumbers, setPoNumbers] = useState([]);
  const [poInfo, setPoInfo] = useState({});
  const [flag, setFlag] = useState(false);
  let allDocuments = [];

  const getPoNumbers = () => {
    setPoNumbers([]);
    return fetch(`/api/cloud/mill/getWOinfo?wo=${woNumber}`)
      .then((response) => response.json())
      .then((responseJson) => {
        responseJson.recordset.map((doc) => {
          allDocuments.push(doc.DocumentNumber);
        });
        setPoNumbers(allDocuments);
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  const getWOStatus = () => {
    return fetch(`/api/cloud/mill/getWOStatus?wo=${woNumber}`)
      .then((response) => response.json())
      .then((responseJson) => {
        setWoStatus(responseJson.recordset);
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  const getPOInfo = (po) => {
    return fetch(`/api/cloud/mill/getPOLines?poNum=${po}`)
      .then((response) => response.json())
      .then((responseJson) => {
        setPoInfo((prevInfo) => ({
          ...prevInfo,
          [po]: responseJson.recordset, // Store each `po`'s data under its own key
        }));
      })
      .catch((error) => {
        //console.error(error);
      });
  };

  useEffect(() => {
    if (poNumbers.length > 0 && !flag) {
      setFlag(false);
      poNumbers.forEach((number) => {
        getPOInfo(number);
      });
    }
  }, [poNumbers]);

  //   useEffect(() => {
  //     console.log(poInfo);
  //   }, [poInfo]);

  const POTable = ({ columns, data }) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
      useTable({
        columns,
        data,
      });

    return (
      <table
        {...getTableProps()}
        style={{ border: "1px solid black", marginBottom: "20px" }}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderBottom: "solid 3px red",
                    background: "aliceblue",
                    color: "black",
                    fontWeight: "bold",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            const isReceived = row.original["Line Status"] === "Received";

            return (
              <tr
                className={isReceived ? "bg-green-300" : "bg-orange-300"}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="p-2 border w-32 border-black"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  const POContainer = ({ data }) => {
    const columns = React.useMemo(
      () => [
        { Header: "Line Number", accessor: "LineNumber" },
        { Header: "Product Code", accessor: "ProductCode" },
        { Header: "Description", accessor: "Description" },
        { Header: "Line Status", accessor: "Line Status" },
        { Header: "Rcv'd On", accessor: "Rcv'd On" },
        { Header: "QTY", accessor: "QTY" },
        { Header: "Bin Location", accessor: "BinLocation" },
        {
          Header: "Date Expected",
          accessor: "DateExpected",
          Cell: ({ value }) => {
            const date = new Date(value);

            if (isNaN(date) || date.getFullYear() === 1969) {
              return "No Date Available";
            }
            return date.toLocaleDateString();
          },
        },
      ],
      []
    );

    return (
      <div className="flex flex-col w-full">
        {Object.keys(data).map((poNumber) => (
          <div className="flex w-full flex-col" key={poNumber}>
            <h2 className="underline font-black">PO Number: {poNumber}</h2>
            <POTable columns={columns} data={data[poNumber]} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full bg-gray-50 min-h-screen">
      <div className="flex w-full m-2 p-2">
        <input
          type="text"
          value={woNumber}
          onChange={(e) => setWoNumber(e.target.value)}
          placeholder="Scan/Type Works Order"
          className="flex w-full h-16 rounded"
        ></input>
        <button
          onClick={() => {
            setWoStatus(null);
            setPoNumbers([]);
            setPoInfo({});
            getPoNumbers();
            getWOStatus();
          }}
          className="w-32 bg-green-300 hover:bg-green-200 m-2 rounded"
        >
          Go!
        </button>
      </div>

      {woStatus && (
        <>
          <div className="flex w-full justify-center pb-2">
            <p className="text-2xl font-black underline">
              Work Order {woNumber}
            </p>
          </div>
          <div className="flex w-full justify-between border-b-2 p-2 bg-gray-200">
            <p>Desc: {woStatus[0].Description[0]}</p>
            <p>
              Date Required:{" "}
              {new Date(woStatus[0].DateRequired).toLocaleDateString()}
            </p>
          </div>

          <div className="flex w-full p-2">
            <POContainer data={poInfo} />
          </div>

          <div className="flex w-full p-2">
            <StockedItems woStatus={woStatus} />
          </div>
        </>
      )}
    </div>
  );
};

export default MillScanner;
